import { graphql, Link } from 'gatsby';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Breadcrumbs from '../components/common/breadcrumbs/Breadcrumbs';
import Layout from '../components/layout/Layout';
import SideBar from '../components/layout/SideBar';
import { getPageLink } from '../constants';
import './AboutUsPage.scss';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { PageContentType } from '../context/PageContentType';

/**
 * About Us Page
 * @param props
 * @constructor
 */
const AboutUsPage: React.FunctionComponent<PageContentType> = props => {
  const { t } = useTranslation();

  const bc = [
    { label: 'home' },
    { label: 'chevron' },
    {
      label: t('breadcrumbs.aboutUs'),
      path: getPageLink(props.pageContext.lang, 'ABOUT_US'),
    },
  ];

  return (
    <Layout
      lang={props.pageContext.lang}
      currentLangUrl={props.pageContext.currentLangUrl}
      otherLangUrl={props.pageContext.otherLangUrl}
      pageId={getPageLink(props.pageContext.lang, 'ABOUT_US')}
      pageTitle={t('aboutUsPage.title')}
    >
      <div className="mdmp__about-us ontario-row">
        <div className="ontario-columns ontario-small-12 ontario-medium-9">
          <Breadcrumbs bc={bc} lang={props.pageContext.lang} />

          <h1>{t('aboutUsPage.title')}</h1>
          <p>
            <Trans i18nKey={'aboutUsPage.paragraph01'}>
              Part1{' '}
              <Link
                className={'mdmp__bold'}
                to={getPageLink(props.pageContext.lang, 'ALL_PRODUCTS_PAGE')}
              >
                Part2
              </Link>
            </Trans>
          </p>

          {/*<h1>{t('aboutUsPage.title2')}</h1>*/}
          {/*<p>{t('aboutUsPage.paragraph02')}</p>*/}

          {/*<h1>{t('aboutUsPage.title3')}</h1>*/}
          {/*<p>{t('aboutUsPage.paragraph03')}</p>*/}

          {/*<h1>{t('aboutUsPage.title4')}</h1>*/}
          {/*<p>*/}

          {/*</p>*/}

          <hr className={'mdmp__hr-black'}></hr>

          <strong>{t('aboutUsPage.onThisPage')}</strong>
          <ol>
            <li>
              <AnchorLink to={'#item01'}>
                {t('aboutUsPage.list.item01.title')}
              </AnchorLink>
            </li>
            <li>
              <AnchorLink to={'#item02'}>
                {t('aboutUsPage.list.item02.title')}
              </AnchorLink>
            </li>
            <li>
              <AnchorLink to={'#item03'}>
                {t('aboutUsPage.list.item03.title')}
              </AnchorLink>
            </li>
            <li className={'mdmp__hide'}>
              <AnchorLink to={'#item04'}>
                {t('aboutUsPage.list.item04.title')}
              </AnchorLink>
            </li>
          </ol>

          <hr></hr>

          <h2>
            <a id={'item01'}>{t('aboutUsPage.list.item01.title')}</a>
          </h2>
          <p>{t('aboutUsPage.list.item01.description')}</p>

          <h2>
            <a id={'item02'}>{t('aboutUsPage.list.item02.title')}</a>
          </h2>
          <p>{t('aboutUsPage.list.item02.description')}</p>

          <h2>
            <a id={'item03'}>{t('aboutUsPage.list.item03.title')}</a>
          </h2>

          <p>
            <Trans i18nKey={'aboutUsPage.list.item03.description'}>
              Part1{' '}
              <a className={'mdmp__bold'} href={t('common.aris.url') + '#aris'}>
                Part2
              </a>
            </Trans>
          </p>
        </div>

        <SideBar />
      </div>
    </Layout>
  );
};

export default AboutUsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
